.othersaboutus-container {
    margin: 100px 0 -450px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 35px 0 0 0;
}

.othersaboutus-central-form {
    max-width: 1100px;
    width: 70%;
    padding: 10px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
}

.othersaboutus-central-sub-form {
    max-width: 1100px;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.othersaboutus-heading {
    color: var(--dark-orange);
    font-size: 16px;
    font-family: "Poppins SemiBold";
}

.othersaboutus-sub-heading {
    color: var(--dark-orange);
    font-size: 14px;
    font-family: "Poppins SemiBold";
    margin-bottom: 4px;
    margin-top: 8px;
}

.othersaboutus-description {
    font-size: 14px;
  }

a { color: inherit; } 
